<template>
  <div>
    <p> Use navigation bar to browse this website </p>
    <h3> Current data usage [TiB] </h3>
    <bar-chart></bar-chart>
    <h3> Current file count [#]</h3>
    <bar-chart2></bar-chart2>
  </div>
</template>

<script>
import BarChart from '@/components/BarChart'
import BarChart2 from '@/components/BarChart2' 
export default {
  components: {
    BarChart,
    BarChart2
  }
}
</script>

<!--
<script>
import LineChart from '@/components/LineChart'
export default {
  components: {
    LineChart
  }
}
</script>
-->
