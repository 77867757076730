import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/HomeView.vue'
Vue.use(VueRouter)

// alert(`Vue version : ${Vue.version}`);
  const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/statistics',
    name: 'Statistics',
    component: () => import('../views/UsageStatistics.vue')
  },
  {
    path: '/chat',
    name: 'Chat',
    component: () => import('../views/MattermostChat.vue')
  },
  {
    path: '/database',
    name: 'Database',
    component: () => import('../views/DatabaseAccess.vue')
  },
  {
    path: '/imprint',
    name: 'Imprint',
    component: () => import('../views/ImprintSection.vue')
  },
  {
    path: '/gdpr',
    name: 'GDPR',
    component: () => import('../views/GPDRSection.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('../views/ContactSection.vue')
  }
]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
export default router

