<script>
  import { Bar } from 'vue-chartjs'

   // import axios from 'axios'
  var client = new XMLHttpRequest();
  var mydata2;
  
  // TODO: Create base url to re-use throughout the frontend
  client.open("GET", "https://sfb1454.iaas.uni-bonn.de/test.json", false);
  client.send();
  mydata2 = JSON.parse(client.responseText);
  var newArrayDataOfOjbect = Object.values(mydata2.projects)
  var allAs = newArrayDataOfOjbect.map(x => Number(x['files']))
  allAs = allAs.map(x => x.toFixed(0))
  var allBs = newArrayDataOfOjbect.map(x => x['name']);
  export default {
    extends: Bar,
    data() {
      return {
        chartData: {
          labels: allBs,
          label: '',
          datasets: [{
            borderWidth: 1,
            backgroundColors: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
              'rgba(75, 192, 192, 1)',
              'rgba(153, 102, 255, 1)',
              'rgba(2, 71, 181, 1)',
              'rgba(193, 253, 111, 1)',
              'rgba(172, 127, 203, 1)',
              'rgba(203, 53, 175, 1)',
              'rgba(226, 45, 44, 1)',
              'rgba(102, 181, 19, 1)',
              'rgba(92, 165, 221, 1)',
              'rgba(250, 40, 162, 1)',
              'rgba(250, 252, 120, 1)',
              'rgba(67, 59, 246, 1)',
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
              'rgba(75, 192, 192, 1)',
              'rgba(153, 102, 255, 1)',
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
              'rgba(75, 192, 192, 1)',
              'rgba(153, 102, 255, 1)',
              ],
              backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(255, 206, 86, 0.2)',
              'rgba(75, 192, 192, 0.2)',
              'rgba(153, 102, 255, 0.2)',                
              'rgba(2, 71, 181, 0.2)',
              'rgba(193, 253, 111, 0.2)',
              'rgba(172, 127, 203, 0.2)',
              'rgba(203, 53, 175, 0.2)',
              'rgba(226, 45, 44, 0.2)',
              'rgba(102, 181, 19, 0.2)',
              'rgba(92, 165, 221, 0.2)',
              'rgba(250, 40, 162, 0.2)',
              'rgba(250, 252, 120, 0.2)',
              'rgba(67, 59, 246, 0.2)',
              'rgba(255, 99, 132, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(255, 206, 86, 0.2)',
              'rgba(75, 192, 192, 0.2)',
              'rgba(153, 102, 255, 0.2)',
              'rgba(255, 99, 132, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(255, 206, 86, 0.2)',
              'rgba(75, 192, 192, 0.2)',
              'rgba(153, 102, 255, 0.2)',                
            ],
            pointBorderColor: '#2554FF',
            data: allAs
          }]
        },
        options: {
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true
              },
              gridLines: {
                display: true
              }
            }],
            xAxes: [{
              gridLines: {
                display: false
              }
            }]
          },
          legend: {
            display: false
          },
          responsive: true,
          maintainAspectRatio: false
        }
      }
    },
    mounted() {
      this.renderChart(this.chartData, this.options)
    }
  }
</script>